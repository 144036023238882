import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../../../../theme/colors";

export type ClassNames =
  | "tableContainer"
  | "tableRow"
  | "cellText"
  | "rowDropdownWrapper"
  | "rowCellDropdownWrapper"
  | "tableCell"
  | "tableHeadCell"
  | "tableHeadBody"
  | "rowTableCell"
  | "alignCenter"
  | "negativeAmount"
  | "mainTextColour"
  | "positiveAmount"
  | "visuallyHidden"
  | "searchIcon";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(() =>
  createStyles({
    tableCell: {
      border: "none",
    },
    tableRow: {
      cursor: "pointer",
      boxShadow: "0px 0px 30px 0px rgba(59, 72, 118, 0.08)",
      borderRadius: 4,
      borderTopLeftRadius: 4,
    },
    cellText: { fontWeight: 600, fontSize: 14, color: "#37393F" },
    rowDropdownWrapper: { backgroundColor: "#F8F8F8", borderRadius: 4 },
    rowCellDropdownWrapper: { padding: "0 0 4px 0" },
    alignCenter: {
      textAlign: "left",
    },
    tableContainer: {
      overflow: "auto",
      boxShadow: "0px 0px 30px 0px rgba(59, 72, 118, 0.08)",
    },
    tableHeadCell: {
      fontWeight: 600,
      fontSize: 14,
      color: "#595D64",
      whiteSpace: "nowrap",
    },
    tableHeadBody: { boxShadow: "0px 0px 30px rgba(59, 72, 118, 0.08)" },
    rowTableCell: { padding: "8px 16px" },
    negativeAmount: {
      color: "#FE5454",
    },
    mainTextColour: {
      color: colors.black.primary,
    },
    positiveAmount: { color: "#669F2A" },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    searchIcon: {
      marginLeft: 4,
      cursor: "pointer",
    },
  })
);
