import { FormControlLabel } from "@material-ui/core";
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";
import { styled } from "@mui/system";
import React, { FC, useContext } from "react";

import OcietyCheckbox from "../../../../../../common/components/OcietyCheckbox";
import { ReactComponent as FilterCheckboxIcon } from "../../../../../../common/svg/icons/filter-checkbox-icon.svg";
import {
  FiltersWithoutDateRange,
  TransactionsAllFilterParams,
} from "../../../../models";
import { TransactionsContext } from "../../../../providers";
import Props from "./Props";
import { useStyles } from "./styles";

const FilterCheckboxPopup: FC<Props> = ({ options, column }: Props) => {
  const classes = useStyles();
  const { setSearchFilters, searchFilters, setTableLoading } =
    useContext(TransactionsContext);
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setAnchor(anchor ? null : event.currentTarget);
  };

  const open = Boolean(anchor);
  const id = open ? "simple-popup" : undefined;

  const handleChange = (event: any) => {
    setTableLoading(true);
    setSearchFilters((prevFilters) => {
      const current = { ...prevFilters };
      const columnFilter =
        current[column as keyof FiltersWithoutDateRange] || [];
      const index = columnFilter.indexOf(event.target.value);

      if (index !== -1) {
        columnFilter.splice(index, 1);
      } else {
        columnFilter.push(event.target.value);
      }

      //@ts-ignore
      current[column as keyof TransactionsAllFilterParams] = columnFilter;

      return current;
    });
    setTableLoading(false);
  };

  return (
    <>
      <div onClick={handleClick} className={classes.searchIcon}>
        <FilterCheckboxIcon aria-describedby={id} type="button" />
      </div>
      <BasePopup id={id} open={open} anchor={anchor}>
        <PopupBody>
          {options.map((option) => (
            <div key={option.id} className={classes.menuItem}>
              <FormControlLabel
                label={option.label}
                control={
                  <OcietyCheckbox
                    value={option.id}
                    checked={Boolean(
                      searchFilters[
                        column as keyof FiltersWithoutDateRange
                      ]?.find((i) => i === option.id)
                    )}
                    onChange={handleChange}
                  />
                }
              />
            </div>
          ))}
        </PopupBody>
      </BasePopup>
    </>
  );
};

export default FilterCheckboxPopup;

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const PopupBody = styled("div")(
  ({ theme }) => `
  width: max-content;
  border-radius: 8px;
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  background-color: ${theme.palette.mode === "dark" ? grey[900] : "#FFF"};
  box-shadow: ${
    theme.palette.mode === "dark"
      ? `0px 4px 8px rgb(0 0 0 / 0.7)`
      : `0px 4px 8px rgb(0 0 0 / 0.1)`
  };
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  z-index: 1;
`
);
